import moment from "moment"

export const useSubtotal = items => {
  let subtotal = items.reduce((previousValue, current) => {
    let itemPrice = current.onSale ? current.salePrice : current.price
    let price = previousValue + itemPrice * current.count
    if (current?.addons?.length == 0) return price

    current.addons.forEach(addon => {
      if (addon.applyPrice) price = price + addon.price
    })

    return price
  }, 0)

  return subtotal
}

export const useDiscount = (subtotal, discount) => {
  let discountToApply = 0

  // Ensure subtotal is a number
  if (!typeof subtotal === "number") return discountToApply

  // Ensure discount object has a type
  if (!discount.hasOwnProperty("type")) return discountToApply

  if (discount.type === "value") {
    discountToApply = discount.value
  } else if (discount.type === "percentage") {
    discountToApply = subtotal * (discount.value / 100)
  }

  return discountToApply.toFixed(2)
}

export const useDiscountedSubtotal = (shoppingCart, discount) => {
  const subtotal = useSubtotal(shoppingCart)

  const appliedDiscount = useDiscount(subtotal, discount)

  return [subtotal, appliedDiscount]
}

export const useTotal = (
  subtotal,
  deliveryFee,
  discount = 0,
  marketCompany = false
) => {
  const marketFee = marketCompany ? parseFloat((subtotal * 0.05).toFixed(2)) : 0
  const total = subtotal + deliveryFee + marketFee - discount
  return total > 0 ? total : 0
}

export const useStoreOpen = (openingDays, todaysHours, storeHours) => {
  const { openingTimeObj, closingTimeObj, openToday } = todaysHours

  const { storeOpenTime, storeCloseTime } = storeHours

  if (!openingDays || !openingDays.length) {
    return { isOpen: false, storeOpenHour: "", storeCloseHour: "" }
  }

  const storeOpenHour = moment(
    (openingTimeObj?._seconds ?? openingTimeObj?.seconds) * 1000
  ).format("h:mmA")
  const storeCloseHour = moment(
    (closingTimeObj?._seconds ?? closingTimeObj?.seconds) * 1000
  ).format("h:mmA")
  const now = moment()

  if (!openToday) {
    return { isOpen: false, storeOpenHour, storeCloseHour }
  }

  let isOpen

  if (storeCloseTime.isBefore(storeOpenTime)) {
    // Handle ranges that span over midnight
    isOpen = now.isAfter(storeOpenTime) || now.isBefore(storeCloseTime)
  } else {
    // Normal range check using an inclusive start time and exclusive end time
    isOpen = now.isBetween(storeOpenTime, storeCloseTime, null, "[)")
  }

  return { isOpen, storeOpenHour, storeCloseHour }
}

export const useStoreNameSlug = storeName => {
  return storeName.toLowerCase().split(" ").join("-")
}

export const useCartTotals = (
  cart,
  orderPickupMethod,
  activeStore,
  deliveryFee,
  discount = 0
) => {
  //Get total of addons
  const addonsTotals = cart.map(el =>
    el.addons.map(addon => parseFloat(addon.price * el.count))
  )
  //Get total of products
  const productTotals = cart.map(el => el.price * el.count)

  //Get total of both!
  const batchTotals = addonsTotals.flat().concat(productTotals)
  const reducer = (accumulator, currentValue) => accumulator + currentValue

  let cartTotal = batchTotals.reduce(reducer, 0)
  const addonsTotal = addonsTotals.flat().reduce(reducer, 0)
  const productsTotal = productTotals.reduce(reducer, 0)

  let finalDiscount = discount
  //Check for discount
  //Checking for discount first because we don't want to apply the discount to
  //our delivery fee
  if (discount) {
    //Checking discount based on value or percentage
    //A discount with a value less than one will always be a percentage
    if (discount <= 1) {
      finalDiscount = (cartTotal * discount).toFixed(2)
      cartTotal = cartTotal - finalDiscount
    } else {
      cartTotal = cartTotal - discount
    }
  }

  //Make sure the Cart Total never falls below 0
  if (cartTotal < 0) {
    cartTotal = 0
  }
  //Check for delivery fee
  if (orderPickupMethod && orderPickupMethod === "Delivery" && activeStore) {
    //Add network delivery fee if partner store
    if (activeStore.onDriverNetwork || activeStore.onHiredDriverNetwork) {
      cartTotal = cartTotal + parseFloat(deliveryFee ?? 0)
    } else {
      cartTotal = cartTotal + activeStore.deliveryFee
    }
  }
  return {
    cartTotal,
    addonsTotal,
    productsTotal,
    discount: finalDiscount,
    percentage: discount && discount <= 1 ? discount * 100 : 0,
  }
}

export default useCartTotals
